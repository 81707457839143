import React, { ReactElement, useState } from 'react';
import { ViewPager } from 'components/view-pager';
import Arrow from 'assets/svg/left.svg';
import { LeftBtnWrapper, RightBtnWrapper, PlaceholderWrapper, PlaceholderItem, Wrapper } from './styled';
import { ListingCardPlaceholder as Placeholder } from 'components/cards/listing/ListingCardPlaceholder';
import { SlideBtn } from 'ds/components/carousels/styled';
import { useTheme } from 'hooks/useTheme';

export interface CarouselProps {
  hasArrows?: boolean;
  hasArrowsWhiteBg?: boolean;
  children: ReactElement[];
  isLoading?: boolean;
  placeholderLength?: number;
  nextClickLimit?: number;
  onScrollLeft?: () => void;
  onScrollRight?: () => void;
}

const Carousel: React.FC<CarouselProps> = props => {
  const {
    hasArrows,
    hasArrowsWhiteBg,
    children,
    isLoading,
    placeholderLength,
    nextClickLimit,
    onScrollLeft,
    onScrollRight,
  } = props;
  const [ activeCarouselSlide, setActiveCarouselSlide ] = useState(0);
  const onPrevClick = () => {
    setActiveCarouselSlide((prev) => prev - 1);
    if (onScrollRight) onScrollRight();
  };
  const onNextClick = () => {
    setActiveCarouselSlide(activeCarouselSlide + 1);
    if (onScrollLeft) onScrollLeft();
  };
  const { isRTL } = useTheme();
  const arrayLength = placeholderLength ? placeholderLength : hasArrows ? 4 : 2;

  const isRightArrowDisplayed =
    hasArrows && nextClickLimit !== 0 && (!nextClickLimit || activeCarouselSlide < nextClickLimit) && activeCarouselSlide !== children.length - 1;
  const isLeftArrowDisplayed = hasArrows && activeCarouselSlide !== 0;

  return (
    <Wrapper
      isLeftArrowDisplayed={isLeftArrowDisplayed}
      isRightArrowDisplayed={isRightArrowDisplayed}
      hasArrowsWhiteBg={hasArrowsWhiteBg}
    >
      {isLoading ? (
        <PlaceholderWrapper>
          {new Array(arrayLength).fill(null).map((_, idx) => (
            <PlaceholderItem key={idx}>
              <Placeholder />
            </PlaceholderItem>
          ))}
        </PlaceholderWrapper>
      ) : (
        <>
          <ViewPager
            slideIndex={activeCarouselSlide}
            onSlideIndexChange={setActiveCarouselSlide}
            verticalAlign="flex-start"
            isRTL={isRTL}
          >
            {children}
          </ViewPager>
          {hasArrows ? (
            <>
              {isLeftArrowDisplayed && (
                <LeftBtnWrapper data-auto="left-slide-btn-wrapper" className="left-slide-btn">
                  <SlideBtn data-auto="left-slide-btn" dir="prev" onClick={onPrevClick}>
                    <Arrow />
                  </SlideBtn>
                </LeftBtnWrapper>
              )}
              {isRightArrowDisplayed && (
                <RightBtnWrapper data-auto="right-slide-btn-wrapper" className="right-slide-btn">
                  <SlideBtn data-auto="right-slide-btn" dir="next" onClick={onNextClick}>
                    <Arrow />
                  </SlideBtn>
                </RightBtnWrapper>
              )}
            </>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default Carousel;
