import { styled } from 'ds';
import { Link } from 'ds/components/link';
import { colorToAlpha } from 'helpers/insight';


export const SearchBlockRoot = styled.div`
  ${props => props.theme.media.showAt({ from: 2 })`
    background-color: ${props.theme.colors.brand.darker};
  `};
  ${props => props.theme.media.showAt({ at: 1 })`
    padding: 22px 16px 16px;
    border-radius: 0 0 12px 12px;
    margin-top: 48px;
  `}
  ${props => props.theme.media.showAt({ at: 2 })`max-width: 456px;`}
  ${props => props.theme.media.showAt({ at: 3 })`max-width: 568px;`}
  ${props => props.theme.media.showAt({ from: 4 })`max-width: 730px;`}
  background-color: ${props => colorToAlpha(props.theme.colors.neutrals.black, 0.25)};
  width: 100%;
  display: flex;
  z-index: 9;
  padding: 6px;
  border-radius: 12px 0 12px 12px;
  position: relative;
  box-shadow: 0 2px 15px 0 rgba(171, 171, 167, 0.4);
`;


export const SearchTypeSelectWrapper = styled.div`
  ${props => props.theme.media.showAt({ at: 1 })`
    width: 100%;
    text-align: center;
    padding-top: 18px;
    height: 48px;
    top: -48px;
    background-color: ${colorToAlpha(props.theme.colors.neutrals.black, 0.25)};
    label:first-of-type {
      margin-right: 7px;
    }
    label:last-of-type {
      margin-left: 9px;
    }
  `};
  background-color: ${props => props.theme.colors.brand.darker};
  height: 32px;
  line-height: 32px;
  top: -32px;
  border-radius: 12px 12px 0 0;
  position: absolute;
  color: ${props => props.theme.colors.neutrals.white};
  ${props => props.theme.isRTL ? 'right' : 'left'}: 0;
  padding: 0 12px;
  font-size: 17px;

  label {
    ${props => props.theme.media.showAt({ at: 1 })`
      color: ${colorToAlpha(props.theme.colors.neutrals.white, 0.5)};
    `};
    color: ${props => props.theme.colors.label.blue};
    padding: 6px 0;
    font-weight: bold;
    position: relative;
    cursor: pointer;

  }

  input[type='radio'] {
    display: none;
  }

  input:checked + label {
    color: ${props => props.theme.colors.neutrals.white};
    margin: 0;
    ${props => props.theme.media.showAt({ at: 1 })`
    ::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: ${props.theme.borderRadius.micro};
      background-color: ${props.theme.colors.neutrals.white};
    }`};
  }
`;

export const InputWrapper = styled.div<{isError?: boolean; isMobile: boolean}>`
  flex: 1;

  .textfield-input {
    border-radius: 0 8px 8px 0;
    height: 54px;
    ${props => props.theme.media.showAt({ at: 1 })`
      height: 48px;
      border: ${props.isMobile ? '0 none' : `2px solid ${props.theme.colors.brand.darker}`}
    `};
  }
  ${({ isError }) => isError ? `
    input::placeholder {
      color: #D0021B;
      font-weight: 600;
    }
  ` : ''}
`;

export const SearchTypeSelectLabel = styled.span`
  ${props => props.theme.media.showAt({ at: 1 })`padding-left: 16px;`};
  padding-left: 24px;
  font-weight: 700;
  color: ${props => props.theme.colors.neutrals.grey10};
`;

export const Separator = styled.span`
  color: ${props => props.theme.colors.neutrals.grey6};
  padding: 0 8px;
`;


export const SearchButton = styled.button`
  font-family: NovemberHebrew;
  ${props => props.theme.media.showAt({ at: 1 })`
    padding: 0 14px;
    width: 108px;
    height: auto;
    display: flex;
    align-items: center;
    line-height: 48px;
    background-color:${props.theme.colors.brand.main};
  `};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
  color: ${props => props.theme.colors.neutrals.white};
  background-color: ${props => props.theme.colors.brand.darker};
  border: none;
  border-radius: 8px 0 0 8px;
  padding: 0 44px;
  height: 54px;
  line-height: 54px;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background-color:${({ theme }) => theme.colors.brand.main};
  }
  :focus {
    outline: none;
  }
`;

export const BulletinsSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 96px auto;
  flex-wrap: wrap;
  ${props => props.theme.media.showAt({ at: 1 })`margin: 0 16px 72px auto; justify-content: start;`};
  ${props => props.theme.media.showAt({ at: 2 })`margin-bottom: 40px;margin-right: 16px;justify-content: start;`};

  & > * {
    :first-of-type {
        ${props => props.theme.media.showAt({ from: 1, to: 2 })`flex-basis: 100% !important;`};
    }

    :last-of-type {
      ${props => props.theme.media.showAt({ from: 2, to: 3 })`display: none`};
    }
  }
`;

export const ListingCardsItemWrapper = styled.div`
  ${props => props.theme.media.showAt({ at: 1 })`flex-basis: 280px;`};
  ${props => props.theme.media.showAt({ at: 2 })`flex-basis: 340px;`};
  ${props => props.theme.media.showAt({ from: 3, to: 4 })`flex-basis: 280px;`};
  ${props => props.theme.media.showAt({ at: 5 })`flex-basis: 322px;`};
`;

export const PowersortCardWrapper = styled.div`
  margin: 0 4px 30px;
  display: flex;

  img {
    display: block;
    ${props => props.theme.media.showAt({ at: 5 })`width: 306px; margin-bottom: 34px;`};
    ${props => props.theme.media.showAt({ from: 3, to: 4 })`width: 280px; margin-bottom: 36px;`};
  }
`;

export const PowersortInnerContent = styled.div`
  position: relative;
  ${props => props.theme.media.showAt({ at: 1 })`flex-basis: 280px !important;`};
  ${props => props.theme.media.showAt({ at: 2 })`flex-basis: 322px !important;`};
`;

export const PowersortInputWrapper = styled.div`
  > a {
    display: block;
    text-align: right;
    outline: none;
    text-decoration: none;
  }
`;

export const PowersortLabel = styled.div`
  font-family: NovemberHebrew;
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.neutrals.grey1};
  line-height: 1.42;
  display:block;
  position:relative;
  margin-bottom:10px;
  ${props => props.theme.media.showAt({ from: 3 })`
    max-width: 185px;position: absolute;
    top: 84px;
    right: 48px;
    margin-bototm:0;
  `};
`;

export const PowersortTotal = styled.div`
  font-family: NovemberHebrew;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.6;
  ${props => props.theme.media.showAt({ from: 3 })`
    position: absolute;
    top: 56px;
    right: 48px;
  `};
`;

export const StyledLink = styled(Link)`
  font-weight: bold;
  margin-top: 16px;
`;

export const HomepageCardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8 };
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
