import styled from '@emotion/styled';
import { ListingCardsItem } from 'ds/components/ListingCard';
import { Link } from 'components/link';

export const Wrapper = styled.div<{ isLeftArrowDisplayed: boolean, isRightArrowDisplayed: boolean, hasArrowsWhiteBg: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
  position: relative;
  margin-top: 24px;

  ${({ theme, hasArrowsWhiteBg, isLeftArrowDisplayed }) => hasArrowsWhiteBg && isLeftArrowDisplayed && `
    ::before {
      content: '';
      position: absolute;
      right: 0;
      height: 100%;
      width: 16px;
      background: ${theme.colors.neutrals.white};
      z-index: 1;
    }
  `}

  ${({ theme, hasArrowsWhiteBg, isRightArrowDisplayed }) => hasArrowsWhiteBg && isRightArrowDisplayed && `
    ::after {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 16px;
      background: ${theme.colors.neutrals.white};
      z-index: 1;
    }
  `}
`;

export const LeftBtnWrapper = styled.div`
  > div {
    ${({ theme }) => theme.isRTL ? 'right' : 'left'}: -15px;
    top: calc(25% - 16px);
  }
`;

export const RightBtnWrapper = styled.div`
  > div {
    ${({ theme }) => theme.isRTL ? 'left' : 'right'}: -15px;
    top: calc(25% - 16px);
  }
`;

export const ItemWrapper = styled.div`
  width: 284px;
  padding: 4px;
  > a {
    outline: none;
    text-decoration: none;
  }
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 21px;
`;

export const PlaceholderItem = styled(ListingCardsItem)`
  overflow: auto;
`;

export const CenteredLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
  text-decoration: none;
`;
